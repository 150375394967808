import React from 'react'
import img from '../../images/lipolaser-cialo-2.webp'

import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const Lipolaser = () => {
  return (
    <>
      <Layout>
        <Seo
          title='Lipolaser | Modelowanie Sylwetki'
          description='Lipolaser - zimny laser diodowy - jest to zabieg dla osób, które chcą
          pozbyć się nadmiaru tkanki tłuszczowej i cellulitu, wymodelować i
          ujędrnić ciało (poprawa metabolizmu) oraz zwiększyć absorbcję
          składników aktywnych.'
          url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/lipolaser'
        />

        <div className='page-container'>
          <div className='page'>
            <div className='content-1'>
              <h1>Lipolaser</h1>
              <div className='section'>
                <div className='image-container long'>
                  <img
                    src={img}
                    width='1536px'
                    height='2048px'
                    alt='Lipolaser zdjęcie'
                  ></img>
                </div>
                <div className='text'>
                  <p>
                    Lipolaser - zimny laser diodowy - jest to zabieg dla osób, które chcą
                    pozbyć się nadmiaru tkanki tłuszczowej i cellulitu, wymodelować i
                    ujędrnić ciało (poprawa metabolizmu) oraz zwiększyć absorbcję
                    składników aktywnych. Skuteczność zabiegu lipolaserem została
                    potwierdzona badaniami klinicznymi. Jest to zabieg odchudzający, a
                    procesy zachodzące podczas zabiegu potocznie porównywane są do procesu
                    wysuszania winogrona, które staje się rodzynką.
                  </p>
                  <p>
                    Zasada działania lipolasera polega na biostymulacji naturalnych
                    kanałów metabolicznych światłem czerwonym o długości 650nm. Pod
                    wpływem przenikającego przez skórę promieniowania laserowego dochodzi
                    do naturalnej reakcji chemicznej powodującej rozbicie trójglicerydów
                    na wolne kwasy tłuszczowe i glicerol, które przekazywane są do układu
                    limfatycznego, a następnie metabolizowane, czyli usuwane naturalna
                    drogą z organizmu. Zabieg jest bezbolesny, nieinwazyjny i nie
                    pozostawia żadnych śladów na skórze. Najlepsze efekty uzyskamy
                    wykonując serię zabiegów, 2 razy w tygodniu. Czas trwania jednego
                    zabiegu to 30 minut.
                  </p>
                  <div className='cennik'>
                    <span>CENNIK</span>
                    <h2>LIPOLASER</h2>
                    <ul>
                      <li>
                        <p>LIPOLASER - WYBRANA PARTIA - POJEDYNCZY ZABIEG</p>
                        <span className='prices'>30 MIN | 140 zł</span>
                      </li>
                      <li>
                        <p>
                          LIPOLASER - WYBRANA PARTIA - <span>PAKIET 8 </span>ZABIEGÓW
                        </p>
                        <span className='prices'>30 MIN | 797 zł</span>
                      </li>
                      <li>
                        <p>
                          LIPOLASER - WYBRANA PARTIA - <span>PAKIET 12 </span>ZABIEGÓW
                        </p>
                        <span className='prices'>30 MIN | 997 zł</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Fade left>
              <Link to='/kontakt'>
                <button>Umów Wizytę</button>
              </Link>
            </Fade>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Lipolaser
